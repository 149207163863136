<template>
  <div v-if="element">
    <q-responsive :ratio="16 / 9">
      <iframe :src="element.content.src" :title="element.content.title" frameborder="0"
        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </q-responsive>
  </div>
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      required: true  // L'objet complet est requis
    }
  }
};
</script>
