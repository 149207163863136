import { createApp } from 'vue';
import App from './App.vue';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';;
import quasarUserOptions from './quasar-user-options';
import router from './router';
import axios from 'axios';

axios.defaults.withCredentials = true;

const app = createApp(App);

// Attacher axios à l'instance globale
app.config.globalProperties.$axios = axios;

// Utiliser Quasar avec les plugins nécessaires (ex: Notify)
app.use(Quasar, {
  plugins: { Notify }, // Ajout du plugin Notify
  ...quasarUserOptions
});

app.use(router);
app.mount('#app');
