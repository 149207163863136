<template>
  <p v-if="element">{{ element.content.content }}</p>
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      required: true  // L'objet complet est requis
    }
  }
};
</script>
