<template>
  <div v-if="element" v-html="element.content.content"></div>
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      required: true  // L'objet complet est requis
    }
  }
};
</script>
