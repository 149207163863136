// File: CourseDirectory.vue
// A component that show a list of links to course elements.

<template>
  <div>
    <q-list bordered separator>
      <q-item v-for="(elementOid, elementTitle) in element.content.elements" :key="elementOid" clickable v-ripple
        :to="'/element/' + elementOid" exact>
        <q-item-section>{{ elementTitle }}</q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>

export default {
  props: {
    element: {
      type: Object,
      required: true
    }
  }
}
</script>
