// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './components/LoginPage.vue';  // Page de login
import HomePage from './components/TechnoCours1.vue'; // Page principale
import ElementViewer from './components/ElementViewer.vue'; // Composant pour visualiser l'élément
import UserAdminPage from './components/UserAdminPage.vue'; // Page d'administration des utilisateurs
import axios from 'axios';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true }  // Protéger cette route
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/element/:oid',  // Route dynamique pour visualiser un élément par son oid
    name: 'ElementViewer',
    component: ElementViewer,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/users',
    name: 'UserAdminPage',
    component: UserAdminPage,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      // Requête pour vérifier si l'utilisateur est authentifié via le cookie
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/me`);
      if (response.data.username) {
        next(); // L'utilisateur est authentifié
      } else {
        next({ name: 'Login' }); // Rediriger vers la page de login
      }
    } catch (error) {
      next({ name: 'Login' }); // En cas d'erreur, rediriger vers login
    }
  } else {
    next(); // Si la route ne nécessite pas d'authentification, continuer
  }
});

export default router;
