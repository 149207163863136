<template>
  <div class="q-my-xl">
    <q-card>
      <q-form @submit="login" class="q-gutter-md">
        <q-card-section>
          <div class="text-h6">Se connecter</div>
        </q-card-section>

        <q-card-section>
          <q-input filled v-model="username" label="Nom d'utilisateur" />
          <q-input filled v-model="password" label="Mot de passe" type="password" />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn label="Login" type="submit" color="primary" />
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  created() {
    // Lire les paramètres GET à partir de l'URL
    const query = this.$route.query;
    if (query.username && query.password) {
      this.username = query.username;
      this.password = query.password;
      this.login(); // Essayer de se connecter automatiquement
    }
  },
  methods: {
    login() {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}/login`, {
        params: {
          username: this.username,
          password: this.password
        }
      })
        // eslint-disable-next-line
        .then(response => {
          window.location.href = this.$router.resolve({ name: 'Home' }).href;
        })
        .catch(error => {
          console.error('Erreur de connexion', error);
        });
    }
  }
}
</script>
