<template>
  <q-layout view="hHh Lpr lff">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="menu" />

        <q-toolbar-title>
          Technologie
        </q-toolbar-title>

        <q-space />

        <q-btn flat dense icon="account_circle">{{ username }}</q-btn>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" overlay bordered class="bg-grey-2">
      <q-list>
        <q-item clickable v-ripple to="/">
          <q-item-section>Home</q-item-section>
        </q-item>
        <q-item clickable v-ripple to="/element/6">
          <q-item-section>Test1</q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page class="q-px-md container">
        <router-view></router-view>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style>
.container {
  max-width: 1280px;
  margin: 0 auto;
  font-size: 1.5em;
}
</style>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
  name: 'LayoutDefault',

  data() {
    return {
      username: null,
    };
  },

  setup() {
    return {
      leftDrawerOpen: ref(false)
    };
  },

  mounted() {
    axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/me`)
      .then(response => {
        this.username = response.data.username;
      })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>
