<!--
  User Admin Page vue component

  This page display all the existing users, their groups, and allow to create a new user.
-->

<template>
  <div class="q-my-xl">
    <q-card>
      <q-card-section>
        <div class="text-h6">Utilisateurs</div>
      </q-card-section>

      <q-card-section>
        <q-item v-for="user in users" :key="user.username">
          <q-item-section>
            <q-item-label>
              <div class="row">
                <div class="col">
                  <q-icon name="person" />
                  {{ user.username }}
                </div>
                <div class="col">
                  <q-icon name="lock" />
                  <q-badge v-for="group in user.groups" :key="group" :label="group" color="primary" />
                </div>
                <div class="col">
                  <q-btn icon="delete" flat color="negative" @click="delete_user(user.username)" />
                </div>
              </div>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>
              <q-form v-on:submit="add_user">
                <div class="row">
                  <q-input class="col" v-model="newUser.username" placeholder="Nom d'utilisateur" outlined dense />
                  <q-input class="col" type="password" v-model="newUser.password" placeholder="Mot de passe" outlined
                    dense />
                  <q-btn class="col" type="submit" label="Ajouter" color="primary" />
                </div>
              </q-form>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      newUser: {
        username: '',
        password: ''
      }
    };
  },
  async mounted() {
    await this.fetch_users();
  },
  methods: {
    async fetch_users() {
      // call backend to get all users
      try {
        const response = await this.$axios.get(`${process.env.VUE_APP_BACKEND_URL}/users`);
        this.users = response.data;
      } catch (error) {
        console.error('Erreur lors du chargement des utilisateurs', error);
        this.$router.push('/');
      }
    },
    async add_user() {
      // call backend to create a new user
      try {
        const response = await this.$axios.post(`${process.env.VUE_APP_BACKEND_URL}/user/${this.newUser.username}`, {
          password: this.newUser.password
        });
        response; // dummy instr to avoid error
        await this.fetch_users(); // refresh users list
      } catch (error) {
        console.error('Erreur lors de la création de l\'utilisateur', error);
      }
    },
    async delete_user(username) {
      // call backend to delete a user
      try {
        const response = await this.$axios.delete(`${process.env.VUE_APP_BACKEND_URL}/user/${username}`);
        response; // dummy instr to avoid error
        await this.fetch_users(); // refresh users list
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'utilisateur', error);
      }
    },
    async update_user(username) {
      // call backend to update a user
      try {
        const response = await this.$axios.put(`${process.env.VUE_APP_BACKEND_URL}/user/${username}`, {
          password: this.newUser.password
        });
        response; // dummy instr to avoid error
        await this.fetch_users(); // refresh users list
      } catch (error) {
        console.error('Erreur lors de la mise à jour de l\'utilisateur', error);
      }
    }
  }
};
</script>