<template>
  <div class="q-my-xl">
    <CourseElement :oid="oid" />
  </div>
</template>

<script>
import CourseElement from '@/components/CourseElement.vue';

export default {
  data() {
    return {
      oid: this.$route.params.oid  // Récupérer l'oid depuis l'URL
    };
  },
  components: {
    CourseElement
  },
  watch: {
    '$route.params.oid': {
      immediate: true,
      handler(newOid) {
        this.oid = newOid;
      }
    }
  }
};
</script>
