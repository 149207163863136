<template>
  <div class="q-my-xl">
    <div class="row">
      <div class="col-xs-12 col-sm-4">
        <p>
          Sur l'image, colorie :
        </p>
        <ul>
          <li style="color: blue;">en bleu les objets qui améliorent l'efficacité des tâches et le confort</li>
          <li style="color: red;">en rouge ceux qui améliorent la sécurité</li>
          <li style="color: green;">en vert ceux qui favorisent la communication</li>
        </ul>
        <p>
          <b>Consignes :</b> Clique sur les rectangles pour changer leur couleur. Une fois que tu as terminé, clique
          sur
          le bouton "Vérifier".
        </p>
      </div>
      <div class="col-xs-12 col-sm-8">
        <svg width="100%" height="100%" viewBox="0 0 1000 1000">
          <image href="@/assets/exo1.jpg" width="100%" height="100%" />
          <rect id="1" x="125" y="200" width="200" height="115" fill="grey" fill-opacity="0.2"
            @click="next_color('1')" />
          <rect id="2" x="150" y="340" width="175" height="115" fill="grey" fill-opacity="0.2"
            @click="next_color('2')" />
          <rect id="3" x="75" y="490" width="250" height="165" fill="grey" fill-opacity="0.2"
            @click="next_color('3')" />
          <rect id="4" x="110" y="700" width="300" height="150" fill="grey" fill-opacity="0.2"
            @click="next_color('4')" />
          <rect id="5" x="365" y="850" width="200" height="115" fill="grey" fill-opacity="0.2"
            @click="next_color('5')" />
          <rect id="6" x="645" y="770" width="230" height="115" fill="grey" fill-opacity="0.2"
            @click="next_color('6')" />
          <rect id="7" x="760" y="45" width="180" height="160" fill="grey" fill-opacity="0.2"
            @click="next_color('7')" />
        </svg>
      </div>
    </div>
    <div class="column">
      <q-btn id="exo1-val-btn" :color="val_color" size="lg" @click="check()">Vérifier</q-btn>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      val_color: 'primary'
    };
  },
  methods: {
    next_color(id) {
      const rect = document.getElementById(id);
      const color = rect.getAttribute('fill');
      if (color === 'blue') {
        rect.setAttribute('fill', 'red');
      } else if (color === 'red') {
        rect.setAttribute('fill', 'green');
      } else {
        rect.setAttribute('fill', 'blue');
      }
    },
    check() {
      // les rectangles :
      const rects = Object.fromEntries(
        ["1", "2", "3", "4", "5", "6", "7"].map(
          x => [x, document.getElementById(x)]
        )
      );
      // stocker la réponse dans un dictionnaire
      const colors = Object.fromEntries(
        Object.entries(rects).map(
          ([key, value]) => [key, value.getAttribute('fill')]
        )
      );
      // Envoyer les couleurs à l'API
      axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/exo1/check`, colors)
        .then(response => {
          if (Object.values(response.data).every(x => x)) {
            this.val_color = 'positive';
          } else {
            this.val_color = 'negative';
          }
          for (const [key, value] of Object.entries(response.data)) {
            const rect = rects[key];
            if (!value) {
              rect.setAttribute('fill', 'grey');
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style></style>