<template>
  <div class="q-pa-md">
    <q-form @submit.prevent="submitResponses">
      <div v-for="(response, index) in responses" :key="index" class="q-mb-md">
        <q-input v-model="responses[index]" placeholder="Saisissez votre proposition" outlined dense>
          <template v-slot:append>
            <q-btn icon="delete" flat color="negative" @click="removeResponse(index)" />
          </template>
        </q-input>
      </div>
      <div class="q-mt-md">
        <q-btn label="Ajouter une proposition" icon="add" @click="addResponse" color="primary" />
      </div>
      <div class="q-mt-md">
        <q-btn label="Soumettre" type="submit" color="secondary" />
      </div>
    </q-form>
  </div>
</template>

<script>
export default {
  props: {
    element: {
      type: Object,
      required: true // L'objet JSON de l'exercice
    }
  },
  data() {
    return {
      responses: [''],  // Initialisation des réponses (sera préremplie si des réponses existent)
    };
  },
  async mounted() {
    // Chargement des réponses existantes si elles existent
    try {
      const existingResponses = await this.$axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/response/${this.element.oid}`);
      if (existingResponses.data && existingResponses.data.content) {
        this.responses = existingResponses.data.content; // Préremplir avec les réponses existantes
      }
    } catch (error) {
      console.error('Erreur lors du chargement des réponses existantes', error);
    }
  },
  methods: {
    addResponse() {
      this.responses.push('');
    },
    removeResponse(index) {
      this.responses.splice(index, 1);
    },
    async submitResponses() {
      try {
        const response = await this.$axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/response/${this.element.oid}`, {
          responses: this.responses
        });
        response; // dummy instr to avoid error
      } catch (error) {
        console.error('Erreur lors de la soumission des réponses', error);
      }
    }
  }
};
</script>
