<template>
  <component :is="elementType" :element="element" />
</template>

<script>
import components from '@/components/Course';  // Map des composants dynamiques
import axios from 'axios';

async function fetchElementFromDatabase(oid) {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/course/element/${oid}`);
    return response.data;
  }
  catch (error) {
    console.error('Erreur lors de la récupération de l\'élément :', error.message);
    return null;
  }
}

export default {
  props: ['oid'],
  data() {
    return {
      element: null,
      elementType: null
    };
  },
  watch: {
    oid: {
      immediate: true,
      async handler(newOid) {
        const element = await fetchElementFromDatabase(newOid);
        this.element = element;

        // Met à jour le type de composant dynamiquement
        if (this.element && this.element.content && this.element.content.type) {
          this.elementType = components[this.element.content.type];
        }
      }
    }
  }
};
</script>
