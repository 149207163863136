<template>
  <div class="q-my-xl">
    <h2 class="text-center">
      La domotique
    </h2>
    <h3>
      Introduction à la domotique
    </h3>
    <p>
      La domotique est l'ensemble des technologies permettant de centraliser et <b>automatiser</b> le contrôle des
      équipements de <b>la maison</b>,
      comme l'éclairage, le chauffage, la sécurité ou encore les appareils électroménagers. Elle vise à améliorer le
      confort, la sécurité et à réaliser des économies d'énergie en automatisant certaines tâches ou en permettant
      un
      contrôle à distance. Grâce à des capteurs intelligents et des systèmes de commande, la domotique s’adapte aux
      besoins des occupants, leur offrant ainsi une maison plus connectée et efficace.
    </p>
    <div>
      <q-responsive :ratio="16 / 9">
        <iframe src="https://www.youtube.com/embed/18iUuweQYbo" title="La domotique, c’est quoi ???" frameborder="0"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </q-responsive>
    </div>
    <h3>
      Problèmatique du cours :
    </h3>
    <p>
      D’après la vidéo que tu viens de visionner, penses-tu que la domotique permettra à l’homme de vivre plus
      confortablement dans sa maison ?
    </p>
    <h3>
      Exercice : L'utilité des systèmes domotiques
    </h3>
    <TechnoExo1 />
    <h3>
      Question : Quel est l'avantage de pouvoir programmer les appareils électroménagers ?
    </h3>
    <div>
      <q-input v-model="reponse1" readonly filled type="textarea" />
    </div>
    <h3>
      1) Les services offerts par la domotique
    </h3>
    <h4>
      Pourquoi la domotique permet-elle une gestion plus efficace de l'énergie ?
    </h4>
    <div>
      <q-input v-model="reponse2" readonly filled type="textarea" />
    </div>
    <h3>
      2) Les objets domotiques dans notre entourage
    </h3>
    <h4>
      Donne les services offerts par les objets suivants :
    </h4>
    <div class="row q-gutter-md text-center">
      <div class="col">
        <h5>Objet technique</h5>
      </div>
      <div class="col">
        <h5>Détecteur de fumée</h5>
      </div>
      <div class="col">
        <h5>Four programmable</h5>
      </div>
    </div>
    <div class="row q-gutter-md text-center">
      <div class="col">
        <h5>Service</h5>
      </div>
      <div class="col">
        <q-input v-model="reponse3_1" readonly filled type="textarea" />
      </div>
      <div class="col">
        <q-input v-model="reponse3_2" readonly filled type="textarea" />
      </div>
    </div>
    <h3>
      3) La gestion informatisée des besoins
    </h3>
    <p>
      Regard à présent la deuxième vidéo : "La domotique vue par Somfy".
    </p>
    <div>
      <q-responsive :ratio="16 / 9">
        <iframe width="1280" height="720" src="https://www.youtube.com/embed/8aFmZ4b22X0"
          title="Provelis Assembleur Conseil : Somfy Domotique  Découvrez l&#39;interface TaHoma" frameborder="0"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </q-responsive>
    </div>
    <h4>Cite quatre services auxquels on peut accéder grâce à l'interface Somfy</h4>
    <div>
      <q-input v-model="reponse4_1" readonly filled type="textarea" />
    </div>
    <h4>Notre téléphone protable nous permet-il d'accéder aux services Somfy ?</h4>
    <div>
      <q-input v-model="reponse4_2" readonly filled type="textarea" />
    </div>
    <h4>Pourquoi est-il intéressant de tout piloter à partir du même endroit ?</h4>
    <div>
      <q-input v-model="reponse4_3" readonly filled type="textarea" />
    </div>
    <h4>Qu'est-ce-qu'un scénario en domotique ?</h4>
    <div>
      <q-input v-model="reponse4_4" readonly filled type="textarea" />
    </div>
    <h4>Imagine un autre service que la télécommande pourrait piloter</h4>
    <div>
      <q-input v-model="reponse4_5" readonly filled type="textarea" />
    </div>
    <h4>Quel est l'intérêt de pouvoir programmer le chauffage ?</h4>
    <div>
      <q-input v-model="reponse4_6" readonly filled type="textarea" />
    </div>
    <h3>
      Bonus : Un dossier sur le domotique
    </h3>
    <div>
      <q-responsive :ratio="16 / 9">
        <iframe width="1280" height="720" src="https://www.youtube.com/embed/5_wSq4gSi-0"
          title="DOMOTIQUE : LA MAISON INTELLIGENTE - Dossier #20 - L&#39;Esprit Sorcier" frameborder="0"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </q-responsive>
    </div>
    <div class="q-py-xl"></div>
  </div>
</template>

<script>
import TechnoExo1 from './TechnoExo1.vue';

export default {
  components: {
    TechnoExo1,
  },
};
</script>

<style></style>