<template>
  <div v-if="element">
    <div v-for="elementOid in element.content.elements" :key="elementOid">
      <CourseElement :oid="elementOid" />
    </div>
  </div>
</template>

<script>
import CourseElement from '@/components/CourseElement.vue';

export default {
  props: {
    element: {
      type: Object,
      required: true  // L'objet complet est requis
    }
  },
  components: {
    CourseElement
  }
};
</script>
