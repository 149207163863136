<template>
  <ol v-if="element" :type="element.content.oltype">
    <div v-for="(elementOid, elementTitle) in element.content.elements" :key="elementOid">
      <li :class="'q-my-md ' + element.content.liclass">{{ elementTitle }}</li>
      <CourseElement :oid="elementOid" />
    </div>
  </ol>
</template>

<script>
import CourseElement from '@/components/CourseElement.vue';

export default {
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  components: {
    CourseElement
  }
}
</script>
